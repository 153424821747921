import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"



export default function Template({ data }) {
    const { markdownRemark: post } = data;

    return (

        <div>
            <Layout>
                <SEO title={post.frontmatter.title} />
                {/* <img src="https://cdn.pixabay.com/photo/2015/02/26/17/56/clock-650753_1280.jpg" width="100%"></img> */}


                <img alt={post.frontmatter.imagealt} src={post.frontmatter.image} width="100%" />
                <p style={{}}>{post.frontmatter.date}</p>

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1>{post.frontmatter.title}</h1>
                        </div>
                    </div>
                </div>

                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Layout>

        </div>
    )
}

export const postQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                image
                description
                imagealt
                date
            }
        }
    }
`